@import "src/styles/helpers";

.contentStyles {
  scroll-margin: -1px;
  :global {
    .empty-paragraph {
      padding-bottom: unset !important;
    }
  }

  padding-top: 80px !important;

  h2 {
    padding-bottom: 48px !important;
  }

  &__withoutPaddingTopForFirstH2 {
    &:nth-child(1) {
      padding-top: 0 !important;
    }
  }

  h2,
  h2 * {
    background-color: transparent !important;
    @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
    font-family: 'KharkivTone', sans-serif !important;
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 40px !important;

    @include media(desktopAll) {
      font-size: 40px !important;
      font-weight: 400 !important;
      line-height: 48px !important;
    }
  }

  h3 {
    padding-bottom: 32px !important;
  }

  h3,
  h3 * {
    background-color: transparent !important;
    @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
    font-family: 'KharkivTone', sans-serif !important;
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 36px !important;

    @include media(desktopAll) {
      font-size: 32px !important;
      font-weight: 400 !important;
      line-height: 40px !important;
    }
  }

  h4 {
    padding-bottom: 24px !important;
  }

  h4,
  h4 * {
    background-color: transparent !important;
    @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
    font-family: 'KharkivTone', sans-serif !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;

    @include media(desktopAll) {
      font-size: 24px !important;
      font-weight: 400 !important;
      line-height: 32px !important;
    }
  }

  h3, h4, h5, h6 {
    padding-top: 22px !important;
  }

  h3, h4, h5, h6 {
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      padding-top: 0 !important;
    }
  }

  &__withParagraphBottomPadding {
    p {
      padding-bottom: 22px !important;
    }

    h2, h3, h4, h5, h6 {
      padding-top: 0 !important;
    }
  }

  p,
  p * {
    background-color: transparent !important;
    @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 28px !important;

    @include media(tablet) {
      font-size: 14px !important;
    }

    @include media(desktopAll) {
      font-size: 16px !important;
      line-height: 32px !important;
      letter-spacing: 0.183px !important;
    }
  }

  p a,
  p a * {
    text-decoration: underline !important;
    cursor: pointer !important;
    @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);

    strong,
    strong * {
      font-weight: 600;
      @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  p strong,
  p strong * {
    @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
    font-weight: 600 !important;
    line-height: 32px !important;
    letter-spacing: 0.183px !important;
  }

  td ul li *,
  td ol li * {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.18px;

    @include media(notMobile) {
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
    }
  }

  li ul {
    margin-top: 16px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 16px;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 50%;
        @include setProperty(background, var(--primary-500), var(--primary-900));
        position: absolute;
        left: 0;
        top: 7px;
      }

      &,
      span {
        @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
        font-family: 'Montserrat', sans-serif !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        line-height: 28px !important;

        strong,
        strong * {
          @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
          font-weight: 600 !important;
          line-height: 32px !important;
          letter-spacing: 0.183px !important;
        }

        @include media(desktopAll) {
          font-size: 16px !important;
          line-height: 32px !important;
          letter-spacing: 0.183px !important;
        }
      }
    }
  }

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: list-item;

    li {
      position: relative;
      padding-left: 35px;
      margin-bottom: 16px;
      counter-increment: list-item;

      &,
      * {
        margin-bottom: 16px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;

        @include media(desktopAll) {
          font-size: 16px;
          font-weight: 300;
          line-height: 32px;
          letter-spacing: 0.183px;
        }
      }

      &::before {
        content: counter(list-item);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.183px;
        position: absolute;
        left: 0;
        top: 4px;
        @include setProperty(background-color, var(--secondary-500), var(--secondary-50));
        @include setProperty(color, var(--primary-500), var(--secondary-500));
      }

      a {
        text-decoration: underline !important;
        cursor: pointer !important;
        @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);

        strong,
        strong * {
          font-weight: 600;
          @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
        }

        &:hover {
          text-decoration: none !important;
        }
      }

      strong,
      strong * {
        font-weight: 600 !important;
        line-height: 32px !important;
        letter-spacing: 0.183px !important;
      }

      span {
        font-weight: 400;
        color: var(--secondary-500);
      }
    }
  }

  figure[class='table'] {
    width: calc(100% + var(--container-padding-mobile) + var(--container-padding-mobile));
    margin-left: calc(var(--container-padding-mobile) * -1);
    margin-right: var(--container-padding-mobile);
    overflow-x: auto;
    @include hideScrollbar;
    margin-bottom: 32px;

    @include media(notMobile) {
      width: 100%;
      margin-left: unset;
      margin-right: unset;
    }
  }

  figure[class='table'] table {
    width: 100%;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    border: 1px solid;
    @include setProperty(border-color, var(--secondary-600), var(--secondary-100));
    margin-left: calc(var(--container-padding-mobile));
    margin-right: var(--container-padding-mobile);

    @include media(notMobile) {
      margin-left: unset;
      margin-right: unset;
    }
  }

  th *,
  td * {
    font-family: 'Montserrat', sans-serif !important;
    background-color: transparent !important;
  }

  th,
  td {
    padding: 6px 12px !important;
    text-align: left !important;
    min-width: 160px !important;
    min-height: 48px !important;
    border-top: 1px solid !important;
    border-left: 1px solid !important;
    @include setProperty(border-color, var(--secondary-500) !important, var(--secondary-100) !important);
    vertical-align: top !important;

    @include media(notMobile) {
      padding: 8px 16px !important;
      min-width: unset !important;
    }
  }

  th {
    @include setProperty(color, var(--white), var(--secondary-500));
    @include setProperty(background-color, var(--secondary-700), var(--secondary-50));
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.18px;


    @include media(notMobile) {
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.183px;
    }
  }

  td,
  td p,
  td span,
  td li {
    @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    letter-spacing: 0.18px !important;

    @include media(notMobile) {
      font-size: 14px !important;
      font-weight: 300 !important;
      line-height: 28px !important;
    }
  }

  td ol li::before {
    width: 18px;
    height: 18px;

    @include media(notMobile) {
      width: 24px;
      height: 24px;
    }
  }

  td:first-child,
  th:first-child {
    border-left: none !important;
  }

  tr:first-child th:not(tbody tr th) {
    border-top: none !important;
  }

  a,
  a * {
    text-decoration: underline;
    cursor: pointer;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    strong,
    strong * {
      @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
    }

    &:hover {
      text-decoration: none;
    }
  }

  figure[class='image'] img,
  p img {
    margin: 8px 0px;
    width: 100%;
    height: auto;
  }

  figure[class='image'] figcaption {
    text-align: center;
    margin-top: 8px;
    @include setProperty(color, var(--secondary-200), var(--secondary-300));
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.183px;
  }
}

.h2Wrapper p:last-child,
.h2Wrapper p:last-child span,
.contentStyles p:last-child,
.contentStyles p:last-child span {
  padding-bottom: 0px !important;
}

.article {

  :global {
    .container {

      @include media(desktop) {
        width: 1040px !important;
      }

      @include media(desktopHuge) {
        width: 1760px !important;
      }

      @include media(desktopBig) {
        width: 1180px !important;
      }

      @include media(desktopHuge) {
        width: 1180px !important;
      }
    }
  }
}

.hero {
  @include setProperty(background, linear-gradient(180deg, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.23) 61.98%, rgba(0, 0, 0, 0.30) 100%), rgba(191, 191, 191, 0.12));
  backdrop-filter: blur(82px);
  @include pageHeaderPadding(32px);
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;

  @include media(notMobile) {
    @include pageHeaderPadding(56px);
    padding-bottom: 56px;
  }

  @include media(portrait) {
    @include pageHeaderPadding(32px);
  }

  @include media(landscape) {
    @include pageHeaderPadding(32px);
  }

  @include media(desktopSmall) {
    @include pageHeaderPadding(32px);
  }

  @include media(desktop) {
    @include pageHeaderPadding(32px);
  }
}

.firstEllipse {
  width: 399.45px;
  height: 41.941px;
  transform: rotate(-19.056deg);
  border-radius: 399.45px;
  @include setProperty(background, var(--primary-400), var(--primary-600));
  filter: blur(82px);
  opacity: 0.4;
  position: absolute;
  left: -300px;
  top: 188px;
  z-index: -1;

  @include media(portrait) {
    left: -200px;
    bottom: 163.941px;
  }

  @include media(landscape) {
    left: -108px;
    bottom: 163.941px;
  }

  @include media(desktopSmall) {
    width: 495.254px;
    height: 52px;
    border-radius: 495.254px;
    left: 13.754px;
    bottom: 162.107px;
  }

  @include media(desktop) {
    width: 495.254px;
    height: 52px;
    border-radius: 495.254px;
    left: 103.754px;
    bottom: 162.107px;
  }

  @include media(desktopBig) {
    width: 495.254px;
    height: 52px;
    border-radius: 495.254px;
    left: 103.754px;
    top: 189.047px;
  }

  @include media(desktopHuge) {
    width: 495.254px;
    height: 52px;
    border-radius: 495.254px;
    left: 103.754px;
    top: 189.047px;
  }
}

.secondEllipse {
  width: 557.562px;
  height: 114.854px;
  transform: rotate(15deg);
  @include setProperty(background, var(--primary-300), var(--primary-500));
  opacity: 0.34;
  filter: blur(82px);
  position: absolute;
  right: -461.509px;
  bottom: -17.547px;
  z-index: -1;

  @include media(portrait) {
    right: -451.509px;
    bottom: 36.453px;
  }

  @include media(landscape) {
    right: -321.509px;
    bottom: 36.453px;
  }

  @include media(desktopSmall) {
    right: -251.509px;
    bottom: 76.453px;
  }

  @include media(desktop) {
    right: -181.509px;
    bottom: 76.453px;
  }

  @include media(desktopBig) {
    right: -181.509px;
    bottom: 116.453px;
  }

  @include media(desktopHuge) {
    right: -181.509px;
    bottom: 116.453px;
  }
}

.titleBlock {
  margin-top: 40px;
  margin-bottom: 24px;

  & a {
    margin-bottom: 16px;
  }

  @include media(portrait) {
    margin-top: 40px;
    margin-bottom: 40px;

    & a {
      margin-bottom: 6px;
    }
  }

  @include media(landscape) {
    margin-top: 40px;
    margin-bottom: 40px;

    & a {
      margin-bottom: 6px;
    }
  }

  @include media(desktopSmall) {
    margin-top: 40px;
    margin-bottom: 64px;

    & a {
      margin-bottom: 6px;
    }
  }

  @include media(desktop) {
    margin-top: 40px;
    margin-bottom: 64px;

    & a {
      margin-bottom: 6px;
    }
  }

  @include media(desktopBig) {
    margin-top: 56px;
    margin-bottom: 64px;

    & a {
      margin-bottom: 6px;
    }
  }

  @include media(desktopHuge) {
    margin-top: 56px;
    margin-bottom: 64px;

    & a {
      margin-bottom: 6px;
    }
  }
}

.title {
  font-family: 'KharkivTone', sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;

  @include media(portrait) {
    line-height: 54px;
  }

  @include media(landscape) {
    line-height: 54px;
  }

  @include media(desktopAll) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 1px;
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.titleButton {
  vertical-align: middle;
  width: 117px !important;

  @include media(notMobile) {
    display: inline-flex !important;
  }
}

.authorBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;


  @include media(notMobile) {
    flex-direction: row;
    gap: 56px;
  }

  @include media(desktopSmall) {
    order: 1;
  }

  @include media(desktop) {
    order: 1;
  }

  @include media(desktopBig) {
    order: 1;
  }

  @include media(desktopHuge) {
    order: 1;
  }
}

.articleDetails {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media(portrait) {
    gap: 32px;
  }

  @include media(landscape) {
    gap: 32px;
  }

  @include media(desktopSmall) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center
  }

  @include media(desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center
  }

  @include media(desktopBig) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center
  }

  @include media(desktopHuge) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center
  }
}

.articleInfo {
  @include setProperty(color, var(--secondary-200), var(--secondary-500));
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.18px;


  @include media(portrait) {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.18px;
  }

  @include media(landscape) {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.18px;
  }

  @include media(desktopSmall) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.183px;
    order: 2;
  }

  @include media(desktop) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.183px;
    order: 2;
  }

  @include media(desktopBig) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.183px;
    order: 2;
  }

  @include media(desktopHuge) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.183px;
    order: 2;
  }

  &__updated {
    margin-right: 8px;
  }

  &__date {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    position: relative;
    margin-right: 64px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -52px;
      width: 40px;
      height: 1px;
      @include setProperty(background, var(--secondary-300), var(--secondary-100));
    }
  }
}

.articleCarousel {
  padding: 8px 0;

  li {
    margin-bottom: 0px !important;
  }

  ul li::before,
  ol li::before {
    content: none !important;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    padding-top: 16px;

    @include media(notMobile) {
      justify-content: space-between;
      padding-top: 8px;
    }

    .arrow {
      cursor: default;
      display: none;
      width: 84px;
      height: 58px;
      position: relative;
      background-color: transparent;
      border-radius: 0;

      @include media(notMobile) {
        display: block;
        transform: none;
      }

      &_prev {
        left: auto;

        img {
          transform: rotate(180deg);
        }
      }

      &_next {
        right: auto;
      }
    }
  }
}

.articleCarouselImage {
  padding: 0px !important;

  img {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
  }

  &__description {
    margin-bottom: 0px !important;
    padding-top: 8px;
    text-align: center;
    @include setProperty(color, var(--secondary-200) !important, var(--secondary-300) !important);
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 28px !important;
    letter-spacing: 0.183px !important;
  }
}

.pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px;
  bottom: 0 !important;
  padding: 0 !important;
  position: static !important;

  &__item {
    @include setProperty(background, url('/media/elps_border_dark.svg') no-repeat center / contain, url('/media/elps_border_light.svg') no-repeat center / contain);
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;

    &:global {

      &.is-active {
        transform: none !important;
        @include setProperty(background, var(--dark-elipse-gradient), var(--light-elipse-grain));
      }
    }
  }
}

.seeMoreArticleCTAContainer {
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  border-bottom: 1px solid var(--primary-500);
  @include setProperty(background-color, var(--secondary-800), var(--secondary-50));
  margin: 32px 0;
  position: relative;
  overflow: hidden;
}

p.seeMoreArticleCTASubtitle,
p.seeMoreArticleCTASubtitle * {
  @include setProperty(color, var(--secondary-200) !important, var(--secondary-300) !important);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.183px !important;
  text-transform: uppercase !important;
  margin-bottom: 16px !important;
  padding-bottom: unset !important;


  @include media(desktopAll) {
    font-weight: 300 !important;
  }
}

.seeMoreArticleCTATitle {
  font-family: inherit;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin-bottom: 24px;
}

.seeMoreArticleCTAEllipse {
  position: absolute;
  width: 245.162px;
  height: 40.529px;
  right: -351.582px;
  bottom: -53.68px;
  transform: rotate(30deg);
  background: var(--primary-400);
  opacity: 0.4;
  filter: blur(85px);

  @include media(portrait) {
    right: -38.582px;
    bottom: -101.68px;
  }

  @include media(landscape) {
    right: 201.418px;
    bottom: -101.68px;
  }

  @include media(desktopSmall) {
    right: 21.418px;
    bottom: -101.68px;
  }

  @include media(desktop) {
    right: 21.418px;
    bottom: -101.68px;
  }

  @include media(desktopBig) {
    right: 117.418px;
    bottom: -101.68px;
  }

  @include media(desktopHuge) {
    right: 117.418px;
    bottom: -101.68px;
  }
}

.main {
  padding-top: 40px;

  @include media(portrait) {
    padding-top: 48px;
  }

  @include media(landscape) {
    padding-top: 48px;
  }

  @include media(desktopSmall) {
    padding-top: 56px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 300px;
    gap: 40px;
  }

  @include media(desktop) {
    padding-top: 56px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 300px;
    gap: 40px;
  }

  @include media(desktopBig) {
    padding-top: 64px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 320px;
    gap: 64px;
  }

  @include media(desktopHuge) {
    padding-top: 64px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 320px;
    gap: 64px;
  }
}

.aside {

  @include media(desktopAll) {
    grid-row: 1;
    grid-column: 2;
  }
}

.asideSticky {

  @include media(desktopAll) {
    position: sticky;
    top: 72px;
    max-height: calc(100vh - 72px);
    overflow: auto;
    @include hideScrollbar;
  }
}

.mainContent {

  @include media(desktopAll) {
    grid-row: 1;
    grid-column: 1;
  }
}

.articleContent {
  position: relative;
}

.navigationSubtitle {
  @include setProperty(color, var(--secondary-200), var(--secondary-300));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.183px;
}

.articleMenuWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  margin-bottom: 80px;
  border: 1px solid;
  border-radius: 8px;
  @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));
  position: relative;
  padding: 24px;
  gap: 18px;
  align-items: flex-start;


  &_mobile {
    flex-direction: column;
    padding: 16px;
  }

  &_openOnMobile {
    @include setProperty(background-color, var(--secondary-800), var(--secondary-50));
  }

  @include media(desktopAll) {
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

.articleMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.articleAnchorForMobileToggleButton {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 14px;
  line-height: 22px;
  letter-spacing: var(--ls-183);
  font-weight: 400;
  text-align: left;
  transition: color 0.3s ease;
  position: relative;

  &_active {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.articleAnchor {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 14px;
  line-height: 22px;
  letter-spacing: var(--ls-183);
  font-weight: 400;
  text-align: left;
  transition: color 0.3s ease;
  position: relative;

  &.hasProgress {
    margin-bottom: 11px;
  }

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &:active {
    @include setProperty(color, var(--primary-200), var(--primary-500));
  }

  &.active {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  p {
    padding-left: 32px;
  }

  .progress {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
    height: 1px;
    @include setProperty(background, var(--secondary-500), var(--secondary-50));

    &__marker {
      position: absolute;
      width: 3px;
      height: 3px;
      @include setProperty(background, var(--primary-500), var(--primary-900));
      border-radius: 50%;
      top: -1px;
    }
  }
}

.socialBar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 32px;

  &__title {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.183px;
    @include setProperty(color, var(--secondary-200), var(--secondary-300));
  }

  &__content {
    display: flex;
    gap: 8px;

    @include media(desktopAll) {
      justify-content: center;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    @include setProperty(background-color, var(--secondary-800), var(--secondary-50));
    border-radius: 8px;
    padding: 8px;
    flex: 1;

    @include media(desktopAll) {
      flex: unset;
    }

    svg {
      width: 24px;
      height: 24px;
      @include setProperty(color, var(--primary-500), var(--secondary-200));
    }

    &:hover {
      @include setProperty(background-color, var(--secondary-700), var(--secondary-50));

      svg {
        @include setProperty(color, var(--primary-500), var(--secondary-500));
      }
    }

    &:active {
      @include setProperty(background-color, var(--secondary-900), var(--secondary-100));

      svg {
        @include setProperty(color, var(--primary-500), var(--secondary-500));
      }
    }
  }


  @include media(landscape) {
    margin-bottom: 0px;
  }

  @include media(desktopAll) {
    margin: 0px;
  }

}

.arrowIcon {
  @include setProperty(color, var(--primary-500), var(--secondary-500));
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  z-index: 1;
  position: absolute;
  top: 24px;
  right: 24px;

  svg {
    width: 24px;
    height: 24px;
  }

  &_open {
    transform: rotate(180deg)
  }
}

.sectionNumber {
  border: 1px solid;
  @include setProperty(border-color, var(--secondary-500), var(--secondary-100));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  @include setProperty(color, var(--secondary-300), var(--secondary-200));
  font-size: 11px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.183px;
  margin-right: 8px;
  position: absolute;


  &_active {
    color: var(--secondary-500) !important;
    background: var(--primary-500);
    border: unset;
  }
}

.listItemWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 18px;

  p {
    padding-left: 32px;
  }
}

.relatedNews {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 104px 0;

  &__header {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: 'KharkivTone', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;

    @include media(desktopAll) {
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__item {
    flex-shrink: 0;
  }

  @include media(portrait) {
    gap: 80px;
  }

  @include media(landscape) {
    padding: 120px 0;
    gap: 80px;

    &__content {
      flex-direction: row;
    }

    &__item {
      flex-shrink: unset;
    }
  }

  @include media(desktopSmall) {
    padding: 120px 0;
    gap: 80px;

    &__content {
      flex-direction: row;
    }

    &__item {
      flex-shrink: unset;
    }
  }

  @include media(desktop) {
    padding: 120px 0;
    gap: 80px;

    &__content {
      flex-direction: row;
    }

    &__item {
      flex-shrink: unset;
    }
  }

  @include media(desktopBig) {
    padding: 120px 0;
    gap: 80px;

    &__content {
      flex-direction: row;
    }

    &__item {
      flex-shrink: unset;
    }
  }

  @include media(desktopHuge) {
    padding: 120px 0;
    gap: 80px;

    &__content {
      flex-direction: row;
    }

    &__item {
      flex-shrink: unset;
    }
  }
}

.blockquote {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 32px 0;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid;
  background: var(--secondary-800);
  @include setProperty(border-color, var(--dark-line-gradient), var(--light-line-gradient));
  @include setProperty(background, var(--secondary-800), var(--secondary-50));

  @include media(notMobile) {
    padding: 24px 40px;
  }

  @include media(desktopAll) {
    padding: 40px;
  }

  .visual {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 40px;
    height: 40px;

    .line {
      flex-grow: 1;
      height: 1px;
      @include setProperty(background, linear-gradient(90deg, rgba(246, 255, 254, 0.00) -33.37%, rgba(155, 188, 183, 0.20) 35.32%, rgba(246, 255, 254, 0.00) 118.57%), linear-gradient(262deg, rgba(81, 81, 81, 0.00) -3.47%, rgba(188, 188, 188, 0.30) 57.43%, rgba(81, 81, 81, 0.00) 107.73%));
    }

    .icon {
      width: 40px;
      height: 40px;
      @include setProperty(color, var(--primary-500), var(--secondary-500));
    }
  }

  .blockquoteText {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.183px;
    margin: 0;
    margin: 32px 0;

    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }

  .authorInfo {
    display: flex;
    gap: 16px;
  }

  .authorImage {
    border-radius: 124px;
    object-fit: cover;
  }

  .authorName {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.183px;
    font-style: normal;
  }

  .authorPosition {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  .ellipse {
    @include setProperty(background, var(--primary-400), var(--primary-900));
    opacity: 0.4;
    filter: blur(85px);
    position: absolute;
    width: 245.162px;
    height: 40.529px;
    transform: rotate(30deg);
    right: -438.582px;
    bottom: -35.68px;

    @include media(portrait) {
      right: -125.582px;
      bottom: -131.68px;
    }

    @include media(landscape) {
      right: 114.418px;
      bottom: -155.68px;
    }

    @include media(desktopSmall) {
      right: -65.582px;
      bottom: -99.68px;
    }

    @include media(desktop) {
      right: -65.582px;
      bottom: -99.68px;
    }

    @include media(desktopBig) {
      right: 30.418px;
      bottom: -99.68px;
    }

    @include media(desktopHuge) {
      right: 30.418px;
      bottom: -99.68px;
    }
  }
}

.videoDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;

  &__icon {
    flex-shrink: 0;
    @include setProperty(color, var(--secondary-200), var(--secondary-300));
    width: 24px;
    height: 24px;
  }

  &__text {
    @include setProperty(color, var(--secondary-200) !important, var(--secondary-300) !important);
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 28px !important;
    letter-spacing: 0.183px !important;
  }
}

.videoContainer {
  padding-bottom: 32px;
}

.videoWrap {
  overflow: hidden;
  height: 0;
  position: relative;
  margin: 8px 0;
  padding-bottom: 76%;

  @include media(portrait) {
    padding-bottom: 60%;
  }

  @include media(landscape) {
    padding-bottom: 45%;
  }

  @include media(desktopSmall) {
    padding-bottom: 57%;
  }

  @include media(desktop) {
    padding-bottom: 57%;
  }

  @include media(desktopBig) {
    padding-bottom: 50%;
  }

  @include media(desktopHuge) {
    padding-bottom: 50%;
  }

}

.videoWrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.video {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding-bottom: 76.25%;
  height: auto;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__controlButton {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    color: var(--light-bg);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 92px;
      height: 92px;

    }

    &:hover,
    &:focus {
      outline: none;
      color: var(--primary-900);
    }
  }
}

// old parse method

.imageBlock {
  width: 100%;
  margin: var(--margin-lg) 0;
  display: flex;
  flex-direction: column;

  @include media(tablet) {
    margin: var(--margin-xl) 0;
  }

  @include media(desktopSmall) {
    margin: 56px 0;
  }

  @include media(desktop) {
    margin: 56px 0;
  }

  @include media(desktopBig) {
    margin: var(--margin-xxl) 0;
  }

  @include media(desktopHuge) {
    margin: 72px 0;
  }

  figcaption {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: var(--ls-183);
    @include setProperty(color, var(--secondary-200), var(--secondary-300));
    font-weight: 300;
    padding-top: 8px;
  }
}

.image {
  position: relative;
  height: auto;
  width: 100%;
  margin: var(--margin-sm) 0;

  img {
    width: 100%;
    height: auto;
  }
}

.conclusion {
  padding: var(--margin-lg) var(--margin-md) 40px;
  margin: var(--margin-lg) 0 0;
  margin-bottom: 28px;
  border: 1px solid;
  @include setProperty(border-image, (linear-gradient(90deg, rgba(42, 54, 52, 0.8), rgba(42, 54, 52, 0.5), rgba(42, 54, 52, 0.1)) 10), (linear-gradient(90deg, rgba(163, 163, 163, 0.8), rgba(163, 163, 163, 0.5), rgba(163, 163, 163, 0.1)) 10));
  font-size: 16px;
  line-height: 2;

  @include media(mobile) {
    margin-bottom: var(--margin-lg);
  }

  @include media(tablet) {
    padding: 38px 58px 58px;
    margin: var(--margin-xl) 0;
    font-size: 20px;
  }

    @include media(desktopAll) {
      margin-bottom: 28px;
    }

  @include media(desktopSmall) {
    margin-top: 56px;
  }

  @include media(desktop) {
    margin-top: 56px;
  }

  @include media(desktopBig) {
    margin-top: var(--margin-xxl);
  }

  @include media(desktopHuge) {
    padding: 38px 58px 58px;
    margin-top: 72px;
    font-size: 28px;
  }

  &__title {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: var(--ls-183);
    font-weight: bold;
    margin-bottom: 18px;
  }

  &__text {
    letter-spacing: var(--ls-183);
    font-weight: 300;
    font-size: inherit;
    line-height: inherit;
  }
}