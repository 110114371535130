@import "src/styles/helpers";

.revealingPoints {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media(notMobile) {
        gap: 80px;
    }

    .revealingPointsTitle {
        font-family: 'KharkivTone', sans-serif;
        font-size: 24px;
        line-height: 32px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
    } 

    .revealingPointsContent {
        display: flex;
        gap: 40px;

        &.reversedOrder {
            flex-direction: row-reverse;
        }
    
        .points {
            display: flex;
            flex-direction: column;
            flex:  1 0 0;

            .pointsImage {
                display: block;
                max-width: 100%;

                @include media(landscape) {
                    display: none;
                }

                @include media(desktopAll) {
                    display: none;
                }
            }
        }

        .pointsImage {
            aspect-ratio: 3 / 2;
            width: 100%;
            height: 100%;
            max-width: 50%;

            @include media(mobile) {
                display: none;
            }

            @include media(portrait) {
                display: none;
            }
        }
    }
}

.revealingPoint {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    padding: 24px 8px;

    &.active {
        .pointContent {

            .pointTitle {
                @include setProperty(color, var(--primary-500), var(--primary-900));
            }

            .pointText {
                display: block;
            }
        }

        .pointIcon {
            transform: rotate(45deg);
        }
    }

    .pointContent {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1 0 0;

        .pointTitle {
            font-family: 'KharkivTone', sans-serif;
            font-size: 20px;
            line-height: 28px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }

        .pointText {
            font-size: 14px;
            line-height: 28px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            display: none;

            @include media(landscape) {
                font-size: 16px;
                letter-spacing: 0.183px;
            }

            @include media(desktopAll) {
                font-size: 16px;
                letter-spacing: 0.183px;
            }
        }
    }

    .pointIcon {
        width: 24px;
        height: 24px;
        transform: rotate(-45deg);
        transition: transform 0.3s ease;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
    }
}