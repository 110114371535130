@import 'src/styles/helpers';


.caseSliderSection {
    display: flex;
    flex-direction: column;
    gap: 59px;
    padding-top: 40px;
    padding-bottom: 40px;

    @include media(desktopAll) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &.doubledView {
        gap: 40px;

        @include media(notMobile) {
            gap: 80px;
        }
    }

    .caseSliderTitle {
        font-family: 'KharkivTone', sans-serif;
        font-size: 24px;
        line-height: 32px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        text-align: center;
    }
}

.slide {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:not(.doubled):first-child {
        @include media(portrait) {
            padding-left: calc(64px - 40px);
        }

        @include media(landscape) {
            padding-left: calc(72px - 40px);
        }

        @include media(desktopAll) {
            padding-left: calc(320px - 40px);
        }

        @include media(desktopSmall) {
            padding-left: calc(120px - 40px);
        }

        @include media(desktop) {
            padding-left: calc(200px - 40px);
        }
    }

    &:not(.doubled):last-child {
        @include media(portrait) {
            padding-right: calc(64px - 40px);
        }

        @include media(landscape) {
            padding-right: calc(72px - 40px);
        }

        @include media(desktopAll) {
            padding-right: calc(320px - 40px);
        }

        @include media(desktopSmall) {
            padding-right: calc(120px - 40px);
        }

        @include media(desktop) {
            padding-right: calc(200px - 40px);
        }
    }
}

.sliderItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    width: 100vw;
    max-width: 678px;
    height: 100%;

    padding-left: 24px;
    padding-right: 24px;

    @include media(notMobile) {
        flex-direction: row;
        padding-left: 40px;
        padding-right: 40px;
    }

    @include media(portrait) {
        gap: 24px;
        padding-left: 20px;
        padding-right: 20px;
    }

    &.doubledView {
        max-width: 100%;
        height: auto;
        justify-content: center;

        .itemContent {
            flex: 1 0 0;

            @include media(notMobile) {
                max-width: 50%;
            }
        }

        @include media(portrait) {
            padding-left: 64px;
            padding-right: 64px;
        }

        @include media(landscape) {
            padding-left: 72px;
            padding-right: 72px;
        }

        @include media(desktopAll) {
            padding-left: 320px;
            padding-right: 320px;
        }

        @include media(desktopSmall) {
            padding-left: 120px;
            padding-right: 120px;
        }

        @include media(desktop) {
            padding-left: 200px;
            padding-right: 200px;
        }
    }

    .itemContent {
        display: flex;
        flex-direction: column;
        gap: 40px;
        height: 100%;

        .sliderItemHead {
            display: flex;
            flex-direction: column;
            gap: 24px;
            height: 100%;

            .itemTitle {
                font-family: 'KharkivTone', sans-serif;
                font-size: 20px;
                line-height: 28px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
            }

            .itemText {
                font-size: 14px;
                line-height: 28px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));

                @include media(landscape) {
                    font-size:16px;
                    letter-spacing: 0.183px;
                }

                @include media(desktopAll) {
                    font-size:16px;
                    letter-spacing: 0.183px;
                }
            }
        }

        .itemImage {
            aspect-ratio: 3 / 2;
            width: 100%;
            height: auto;
        }
    }
}

.sliderTrack {

    &.withSideShadow {
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            z-index: 10;

            @include media(notMobile) {
                width: calc((100vw - 678px - 60px) / 2);
            }
        }

        &::before {
            left: 0;
            @include setProperty(background, linear-gradient(90deg, #141414 0%, rgba(20, 20, 20, 0.00) 85%), linear-gradient(90deg, #F6F6F6 0%, rgba(20, 20, 20, 0.00) 85%));
        }
        &::after {
            right: 0;
            @include setProperty(background, linear-gradient(270deg, #141414 0%, rgba(20, 20, 20, 0.00) 85%), linear-gradient(270deg, #F6F6F6 0%, rgba(20, 20, 20, 0.00) 85%));
        }
    }
}

.navigation {
    position: relative;

    &.centeredView {
        max-width: 598px;
        margin: auto;

        .arrow {

            &_prev {
                left: 0;
            }

            &_next {
                right: 0;
            }
        }
    }

    .arrow {
        z-index: 2;
        background-color: transparent;
        width: 136px;
        border-radius: 0;
        display: none;
        cursor: default;
        opacity: 1;
      
        @include media(notMobile) {
          display: block;
          width: 84px;
          height: 58px;
        }
      
        &_prev {
          img {
            transform: rotate(180deg)
          }
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
  
    &__item {
      @include setProperty(background, url('/media/elps_border_dark.svg') no-repeat center / contain, url('/media/elps_border_light.svg') no-repeat center / contain);
      width: 16px;
      height: 16px;
      margin: 0;
      border-radius: 50px;
      opacity: 0.7;

      &.active {
        transform: none;
        @include setProperty(background, var(--dark-elipse-gradient), var(--light-elipse-grain));
      }
    }
  }