@import "src/styles/helpers";

.case {
  overflow: hidden;
}

.caseHero {
  @include pageHeaderPadding;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    @include setProperty(background, (linear-gradient(179deg, rgba(20, 20, 20, 0.00) 62.14%, #141414 93.42%), linear-gradient(357deg, rgba(20, 20, 20, 0.00) 80.12%, rgba(20, 20, 20, 0.20) 87.78%), linear-gradient(306deg, rgba(20, 20, 20, 0.00) 21%, rgba(20, 20, 20, 0.50) 65.17%)), (linear-gradient(179deg, rgba(235, 235, 235, 0.00) 62.14%, #f0f0f0 93.42%), linear-gradient(357deg, rgba(235, 235, 235, 0.00) 80.12%, rgba(235, 235, 235, 0.20) 87.78%), linear-gradient(306deg, rgba(235, 235, 235, 0.00) 21%, rgba(235, 235, 235, 0.5) 65.17%)));
  }

  .caseHeroContent {
    position: relative;
    padding-top: 32px;
    padding-bottom: 48px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    @include media(landscape) {
      gap: 80px;
      padding-bottom: 100px;
    }

    @include media(desktopAll) {
      gap: 80px;
      padding-bottom: 75px;
    }

    @include media(desktopBig) {
      padding-bottom: 120px;
    }

    .caseLogo {
      max-height: 48px;
    }

    .caseTitle {
      font-family: 'KharkivTone' sans-serif;
      font-size: 36px;
      line-height: 44px;
      @include setProperty(color, var(--primary-50), var(--secondary-500));

      @include media(landscape) {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 1px;
        max-width: 698px;
      }

      @include media(desktopAll) {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 1px;
        max-width: 698px;
      }
    }

    .caseStats {
      display: flex;
      align-items: center;
      gap: 16px;

      .statItem {
        padding: 0 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include media(notMobile) {
          padding: 0;
          width: 180px;
        }

        .statTitle {
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.18px;
          @include setProperty(color, var(--primary-50), var(--secondary-500));

          @include media(portrait) {
            font-size: 14px;
            line-height: 28px;
          }

          @include media(landscape) {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.183px;
          }

          @include media(desktopAll) {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.183px;
          }
        }

        .statValue {
          font-family: 'KharkivTone' sans-serif;
          font-size: 16px;
          line-height: 24px;
          @include setProperty(color, var(--primary-50), var(--secondary-500));

          @include media(landscape) {
            font-size: 20px;
            line-height: 28px;
          }

          @include media(desktopAll) {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.hero {
  padding-bottom: var(--margin-xxl);
  padding-top: 124px;


  @include media(landscape) {
    padding-top: 184px;
  }

  @include media(desktopAll) {
    display: grid;
    grid-template-columns: 568px 1fr;
    gap: 0 96px;
    padding-top: 184px;
  }

  .title {
    position: relative;
    margin-bottom: var(--margin-xxl);

    @include media(landscape) {
      line-height: 56px;
      font-size: 48px;
    }

    @include media(desktopAll) {
      margin-bottom: 0;
      line-height: 64px;
    }

    .titleButton {
      display: none;
      margin-right: 10px;

      @include media(landscape) {
        display: inline-flex;
        float: inline-start;
      }

      @include media(desktopAll) {
        display: inline-flex;
        float: inline-start;
      }

      &_mobile {
        display: flex;
        margin-top: var(--margin-sm);
        margin-right: 0;

        @include media(landscape) {
          display: none;
        }

        @include media(desktopAll) {
          display: none;
        }
      }
    }
  }

  &__image {
    grid-column: 1 / 3;
    margin-top: var(--margin-xl);
    margin-bottom: var(--margin-xl);

    @include media(tablet) {
      margin-top: 96px;
      margin-bottom: 96px;
    }

    @include media(desktopAll) {
      margin-top: 156px;
      margin-bottom: 156px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-md);
    margin-bottom: var(--margin-lg);
  }

  &__tag {
    font-family: 'KharkivTone', sans-serif;
    font-size: 14px;
    line-height: 1;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: var(--ls-183);
    line-height: 2;

    p {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.projectDetails {
  grid-column: 1 / 3;
  display: flex;
  gap: var(--margin-lg);
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 56px;
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
}

.projectDetail {
  text-align: center;
  margin: 0 auto;

  &__title {
    font-size: 14px;
    line-height: 2;
    margin-bottom: var(--margin-xs);
    font-weight: 300;
  }

  &__value {
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}